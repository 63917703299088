////
/// breckwoldt.net
/// @group layout
////

/// Grid
.container {
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 30px;

  .grid {
    display: grid;
    grid-gap: rem-calc(30);

    &.grid-half {
      @media #{$breakpoint-sm} {
        grid-template-columns: 1fr 1fr;
      }
    }

    &.grid-third {
      @media #{$breakpoint-sm} {
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  .article,
  .aside {
    //
  }
}
