////
/// breckwoldt.net
/// @group base
////

// Invisible Content Just for Screen Reader Users
// http://webaim.org/techniques/css/invisiblecontent/
.screenreader {
  position: absolute;
  top: auto;
  left: rem-calc(-1000);
  width: rem-calc(1);
  height: rem-calc(1);
  overflow: hidden;
}

// Responsive helper
.hidden {
  display: none;
}

.hidden-sm {
  display: none;

  @media #{$breakpoint-sm} {
    display: block;
  }
}

.hidden-md {
  display: none;

  @media #{$breakpoint-md} {
    display: block;
  }
}
