////
/// breckwoldt.net
/// @group base
////

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-shadow: none;
}

// Font "Ubuntu"
// License: UBUNTU FONT LICENCE.txt

@font-face {
  font-family: "Ubuntu-Regular";
  src:
    url("/asset/static/font/ubuntu-regular.woff2") format("woff2"),
    url("/asset/static/font/ubuntu-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Regular-Italic";
  src:
    url("/asset/static/font/ubuntu-regular-italic.woff2") format("woff2"),
    url("/asset/static/font/ubuntu-regular-italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Medium";
  src:
    url("/asset/static/font/ubuntu-medium.woff2") format("woff2"),
    url("/asset/static/font/ubuntu-medium.woff") format("woff");
  font-display: swap;
}
