////
/// breckwoldt.net
/// @group base
////

// Header mark
@keyframes highlight-grow {
  from {
    background-position: -0.1%;
    color: #fff;
  }

  to {
    background-position: -99.99%;
    color: #111;
  }
}

// Page transition for <router-view />
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-active {
  transition-delay: 0.15s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0.5;
}

// Footer heart
@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  90% {
    transform: scale(1);
  }

  92% {
    transform: scale(1.4);
  }

  94% {
    transform: scale(1.2);
  }

  96% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}
