////
/// breckwoldt.net Styles
/// @group abstract
////

// Basic colors
$color-white: #fff;
$color-black: #333;
$color-gray-dark: #666;
$color-gray-medium: #888;
$color-gray-light: #dedede;
$color-gray-light-extra: #efefef;

// Font
$font-regular: "Ubuntu-Regular", Verdana, sans-serif;
$font-italic: "Ubuntu-Regular-Italic", Verdana, sans-serif;
$font-bold: "Ubuntu-Medium", Verdana, sans-serif;

// Breakpoint
$screen-xs-size: rem-calc(380);
$screen-sm-size: rem-calc(550);
$screen-md-size: rem-calc(800);
$screen-lg-size: rem-calc(1200);

// Media Queries
$breakpoint-xs: "(max-width: #{$screen-xs-size})";
$breakpoint-sm: "(min-width: #{$screen-sm-size})";
$breakpoint-md: "(min-width: #{$screen-md-size})";
$breakpoint-lg: "(min-width: #{$screen-lg-size})";

// Border
$border-radius: 2px;
$border-transparent: rgba(255, 255, 255, 0.3);

// Opacity
$opacity-low: 0.9;
$opacity-medium: 0.6;
$opacity-high: 0.2;
