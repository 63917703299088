@import "@/scss/abstract/_unit.scss","@/scss/abstract/_variable.scss";































.header {
  a.active {
    font-family: $font-regular;
    cursor: default;
  }
}
