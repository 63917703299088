////
/// breckwoldt.net
/// @group layout
////

/// Main footer component
.footer {
  padding: 0 40px;

  @media #{$breakpoint-sm} {
    padding: 0 50px;
  }

  nav {
    padding: 20px 0;
    text-transform: uppercase;

    a {
      padding-right: 20px;
      line-height: 30px;
    }
  }

  .heart {
    display: inline-block;
    position: relative;
    top: 1px;
    padding: 0 2px;
    animation: pulsate 8s ease-out infinite;
    font-size: rem-calc(18);
  }

  .icon {
    &.icon-home-outline,
    &.icon-heart-outline {
      position: relative;
      top: 2px;
      width: 17px;
      height: 17px;
      fill: $color-white;
    }
  }
}
