////
/// breckwoldt.net
/// @group layout
////

/// Main header component
.header {
  padding: 0 40px;

  @media #{$breakpoint-sm} {
    padding: 0 50px;
  }

  .logo {
    margin-bottom: 10px;

    span {
      font-size: rem-calc(30);

      @media #{$breakpoint-md} {
        font-size: rem-calc(40);
      }

      &::before {
        // &#x2B22;
        content: "\2B22";
        position: relative;
        top: 5px;
        left: -8px;
        font-size: rem-calc(60);

        @media #{$breakpoint-md} {
          top: 7px;
          left: -10px;
          font-size: rem-calc(80);
        }
      }
    }
  }

  .mark {
    padding: 2px 5px;
    transition: all 0.5s ease-out;
    animation-name: highlight-grow;
    animation-duration: 1.1s;
    animation-timing-function: ease-out;
    animation-delay: 2s;
    border-radius: $border-radius;
    background-image: linear-gradient(to right, transparent 50%, #ffc600 50%);
    background-position: 0% 0;
    background-size: 200% auto;
    color: white;
    font-size: rem-calc(14);
    animation-fill-mode: forwards;

    @media #{$breakpoint-md} {
      font-size: rem-calc(16);
    }
  }
}
