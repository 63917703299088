////
/// breckwoldt.net
/// @group base
////

/// Reset
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

// Box sizing
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// List
ol,
ul {
  list-style: none;
}

// Mark, Button
mark,
button {
  background: none;
}

// Separator
hr {
  width: 100%;
  border: none;
}

// Image and Video
img,
video {
  display: block;
  width: 100%;
}

// Iframe
iframe {
  width: 100%;
  overflow: hidden;
  border: 0;
}
