////
/// breckwoldt.net
/// @group base
////

/// Basic styling goes here, this are gerneral defintions for the project
:root {
  background-color: $color-black;
}

body {
  color: $color-gray-light;
  font-family: $font-regular;
  font-size: rem-calc(16);
}

// Headlines
h1,
.h1 {
  display: block;
  margin-bottom: 30px;
  color: $color-white;
  font-family: $font-bold;
  font-size: rem-calc(20);

  @media #{$breakpoint-md} {
    font-size: rem-calc(35);
  }
}

h2,
.h2 {
  display: block;
  margin-bottom: 20px;
  color: $color-white;
  font-family: $font-bold;
  font-size: rem-calc(22);

  @media #{$breakpoint-md} {
    width: 70%;
    font-size: rem-calc(30);
  }
}

h3,
.h3 {
  display: block;
  margin-bottom: 20px;
  color: $color-white;
  font-family: $font-bold;
  font-size: rem-calc(18);

  @media #{$breakpoint-md} {
    font-size: rem-calc(25);
  }
}

h4,
.h4 {
  display: block;
  color: $color-white;
  font-family: $font-bold;
  font-size: rem-calc(18);

  @media #{$breakpoint-md} {
    font-size: rem-calc(20);
  }
}

a {
  transition: color ease-out 200ms;
  color: $color-gray-light;
  text-decoration: none;

  &.active {
    font-family: $font-bold;
  }

  &:hover {
    color: $color-white;
  }
}

a[href^="mailto"] {
  color: $color-white;
}

blockquote {
  color: $color-white;
  font-family: $font-italic;
}

mark {
  display: inline-block;
}

b,
strong {
  font-family: $font-bold;
}

.paragraph {
  display: block;
  margin-bottom: rem-calc(10);
  line-height: rem-calc(22);
  hyphens: auto;

  &:last-child {
    margin-bottom: 0;
  }
}

hr,
.hr {
  margin: 30px 0;
  border-top: 1px solid $border-transparent;

  @media #{$breakpoint-md} {
    margin: 30px 0;
  }

  @media #{$breakpoint-lg} {
    margin: 60px 0;
  }
}

i,
em {
  font-family: $font-italic;
}

// Text
small,
.small {
  font-size: rem-calc(12);
  line-height: rem-calc(20);
}

// Miscellaneous
::selection {
  background-color: $color-white;
  color: $color-gray-dark;
}
