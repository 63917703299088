////
/// breckwoldt.net
/// @group layout
////

/// Main
.main {
  padding: 0 40px;

  @media #{$breakpoint-sm} {
    padding: 0 50px;
  }
}
